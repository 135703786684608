<template>
  <header class="flex">
    <va-card color="info"
             gradient>
      <va-card-title><h3 class="va-h3">pravilovkusa.ru</h3></va-card-title>
    </va-card>
  </header>
  <content class="flex">
    <div class="row">
      <div class="flex flex-col md4 xs12">
        <va-card
          stripe
          stripe-color="success"
        >
          <va-card-title>
            <h3 class="va-h3 va-text-center"> Умные зеркала. Вкус в деталях.</h3>

          </va-card-title>
          <va-card-content>
            <p class="va-text-secondary va-text-center">изготовление зеркальных телевизоров</p>
          </va-card-content>

          <va-card-content
          >
            <a href="https://tv-zerkalo.ru">
            <va-image
              src="/img/tvzerkalo.jpg"
              style="max-width: 500px;margin: 0 auto"
            ></va-image>
            </a>
          </va-card-content>
        </va-card>
      </div>
      <div class="flex flex-col md4 xs12">
        <va-card
          stripe
          stripe-color="success"
        >
          <va-card-title>
            <h3 class="va-h3 va-text-center">Просто вкусно</h3>

          </va-card-title>
          <va-card-content>
            <p class="va-text-secondary va-text-center">магазин деликатесов</p>
          </va-card-content>
          <va-card-content>
            <div class="row">
              <div class="flex md6 xs12">
                <va-alert color="#6775c1" outline>
                  <a href="https://www.wildberries.ru/seller/969842"><img src="/img/wildberries.jpg"></a>
                </va-alert>
              </div>
              <div class="flex md6 xs12">
                <va-alert color="#6775c1" outline>
                  <a href="https://www.ozon.ru/seller/pravilovkusa-1205151/produkty-pitaniya-9200/?miniapp=seller_1205151">
                    <img src="/img/ozon.png">
                  </a>
                </va-alert>
              </div>
            </div>
          </va-card-content>
          <va-card-content>
              <img
                src="/img/vk.jpeg"
                style="max-width: 500px;margin: 0 auto"
              >
          </va-card-content>
        </va-card>
      </div>
      <div class="flex flex-col md4 xs12">
        <va-card
          stripe
          stripe-color="success"
        >
          <va-card-title>
            <h3 class="va-h3 va-text-center">Интерьеры со вкусом</h3>

          </va-card-title>
          <va-card-content>
            <p class="va-text-secondary va-text-center">студия интерьерного дизайна</p>
          </va-card-content>
          <va-card-content>
            <a href="https://instagram.com/zaharovadesign">
              <va-image
                src="/img/diz.jpeg"
                style="max-width: 500px;margin: 0 auto"
              ></va-image>
            </a>
          </va-card-content>
        </va-card>
      </div>
    </div>

    <va-card
      color="backgroundPrimary"
      style="padding: 0.75rem;"
      stripe
      stripe-color="success"
    >
      <div class="row">
        <div
          class="flex flex-col"
          style="width: 100%;"
        >
          <div class="item">
            <h3 class="va-h4">Желаете купить оптовую партию деликатесов по выгодным ценам. </h3>
          </div>
          <va-card-content>
            <div class="row" style="max-width: 400px; margin: 0 auto">
              <va-input
                class="flex flex-col xs12"
                input-class=""
                v-model="form.firstName"
                :rules="[(value) => (value && value.length > 0) || 'Имя обязательно к заполнению']"
                label="Ваше имя"
                outline
              />

              <va-input
                v-model="form.email"
                :rules="[(value) => (value && value.length > 0) || 'Почту обязательно']"
                label="Почта"
                class="flex flex-col xs12 "
                outline
              />

              <va-input
                v-model="form.phone"
                :rules="[(value) => (value && value.length > 0) || 'Почту обязательно']"
                label="Телефон"
                class="flex flex-col xs12"
                outline
              />

              <va-input
                v-model="form.message"
                label="Сообщение"
                type="textarea"
                outline
              />

              <va-button @click="submitForm" tag="a"
                         class="flex flex-col xs12"  outline
              >
                Отправить запрос
              </va-button>
            </div>

          </va-card-content>
        </div>
      </div>
    </va-card>
  </content>
  <footer class="flex">

  </footer>
</template>

<script>
// @ is an alias to /src

import { VaInput } from 'vuestic-ui'
import axios from 'axios'

export default {
  name: 'HomeView',
  components: { VaInput },
  data () {
    return {
      form: {
        firstName: '',
        email: '',
        phone: '',
        message: ''
      }
    }
  },
  methods: {
    async submitForm () {
      if (this.form.firstName.length === 0 || this.form.email.length === 0) {
        alert('Заполните поля формы')
        return false
      }
      console.dir(this.form)
      const res = await axios('/actions/mail.php', {
        method: 'POST',
        data: this.form,
        headers: {
          'Content-type': 'application/json'
        }
      })
      if (res.data === true) {
        alert('Ваш запрос получен')
      } else {
        alert('Что-то пошло не так, попробуйте еще раз')
      }
    }
  }

}
</script>
<style lang="scss" scoped>

.va-card__content img {
  width: 100%;
}

.xs12 .va-h3 {
    margin: 0.5rem auto;
}

content {
  margin-top: 10px;
  display: block;
}

</style>
